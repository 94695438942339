
import { Vue, Component, Prop, Watch, } from 'vue-property-decorator'
import NeedHelp from '@/components/V2/NeedHelp/Index.vue'
import InputUrl from '@/components/InputUrl/Index.vue'
import ButtonInputUrl from '@/components/ButtonInputUrl/Index.vue'
import SelectUserComponent from '@/components/SelectUser/Index.vue'
import LongDescriptionHandlerComponent from '@/components/LongDescriptionHandler/Index.vue'
import ChipHackmetrix from '@/components/Chip/Index.vue'
import { UrlsInterface, } from '@/store/modules/maintenance/MaintenanceModule'
import { DataroomTemplate, } from '@/store/types/Dataroom/DataroomTemplate'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

import { mapGetters, } from 'vuex'
@Component({
  components: {
    NeedHelp,
    InputUrl,
    ButtonInputUrl,
    SelectUserComponent,
    ChipHackmetrix,
    LongDescriptionHandlerComponent,
    OverlayBackground
  },
  computed: {
    ...mapGetters('ActivitiesModule', ['getSelectedActivity',]),
    ...mapGetters('SteppersModule', ['getStepperStay',]),
    ...mapGetters('ActivitiesModule', { selectedActivity: 'getSelectedActivity', }),
  },
})
export default class SideNavImplementationComponent extends Vue {
  @Prop({ required: false, }) responseData!: any
  private business = this.$store.state.BusinessModule.BusinessState.businessSelected.company
  private gapControlList = this.$store.state.GapModule.GapState.controlList.gap_analysis_controls
  public selectedActivity: any
  public usersProfile: any
  public openComments = true
  public open = true
  public urls_array: UrlsInterface[] = []
  private deleteRoom: any[] = []
  private currentDatarooms: DataroomTemplate[] = []
  private fileToUpload: FileUploadInterface[] = []
  private files = []
  private dataroomsName: string[] = []
  private url = ''
  private username =
    this.$store.state.AuthModule.AuthState.name +
    ' ' +
    this.$store.state.AuthModule.AuthState.last_name
  private datarooms: string[] = []
  private userObject = {}
  private showUserSelect = false
  private modalDatePicker = false
  private valueAux = ''
  private statusAux = 0
  private statusFull = ''
  private statusBefore = ''
  private watchAux = false
  private tasks: any[] = []
  private loading = false;
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];

  @Watch('responseData')
  changeData (): void {
    this.url = this.responseData.data.url
    this.dataroomsName = []
    this.fileToUpload = []
    this.statusFull = this.responseData.data.status
    this.statusBefore = this.responseData.data.status
    this.currentDatarooms = this.responseData.data.evidence_files
    this.urls_array = this.responseData.data.activity_urls.map((url: UrlsInterface) => ({
      link: url.link || '',
      id: url.id || '',
    }))
    this.userObject = this.responseData.data.user ? this.responseData.data.user : {}
    this.showUserSelect = true
    this.tasks = this.responseData.data.tasks
  }

  private selectChip (value: any) {
    if (this.gapControlList) {
      if (!this.ADMIN_ROLES.some((adminRole: string) => this.$store.state.AuthModule.AuthState.role.includes(adminRole))){
        this.$router.push({
          name: 'startupDetailControl',
          params: {
            id_control: value.id ? value.id.toString() : 'null',
            id_category: value.category_id ? value.category_id.toString() : 'null',
          },
          query: { redirect: 'true', },
        })
      } else {
        this.$store.commit('GapModule/SET_DETAIL_SELECTED', value)
        this.$router.push({
          name: 'editControl',
          params: {
            id: this.$route.params.id,
            id_control: value.id ? value.id.toString() : 'null',
            id_category: value.category_id ? value.category_id.toString() : 'null',
          },
          query: { redirect: 'true', },
        })
      }
    } else {
          this.loading = true

      this.$store.dispatch('GapModule/getGapControlList', {categoryId:value.category_id}).then(() => {
            this.loading = false

        if (!this.ADMIN_ROLES.some((adminRole: string) => this.$store.state.AuthModule.AuthState.role.includes(adminRole))){
          this.$router.push({
            name: 'startupDetailControl',
            params: {
              id_control: value.id ? value.id.toString() : 'null',
              id_category: value.category_id ? value.category_id.toString() : 'null',
            },
            query: { redirect: 'true', },
          })
        } else {
          this.$router.push({
            name: 'editControl',
            params: {
              id: this.$route.params.id,
              id_control: value.id ? value.id.toString() : 'null',
              id_category: value.category_id ? value.category_id.toString() : 'null',
            },
            query: { redirect: 'true', },
          })
        }
      })
    }
  }

  private goToMaintenance () {
    this.$mixpanel.track('Ir a documentos - SideNav', { Actividad: this.selectedActivity.name, })
    this.$router.push({
      name: 'maintenanceDetail',
      params: { activity_id: this.$route.params.task_id, },
    })
  }
  private validDone (value: any, status: any) {
    this.valueAux = value
    this.statusAux = value
    if (value === 'done') {
      const valids = this.validationsDone()
      /// Validaciones antes de llegar a Back
      if (!valids) {
        this.watchAux = !this.watchAux
        return
      }
      return
    } else {
      this.getValue(value, status)
    }
  }
  private updateUser (value: any) {
    const data: any = {
      act_id: this.selectedActivity.id,
      user_id: value.id,
    }
        this.loading = true

    this.$store
      .dispatch('ActivitiesModule/updateActivity', data)
      .then(() => {
        this.$mixpanel.track('Responsable asignado - Impl', {
          Usuario: value.full_name,
          Actividad: this.selectedActivity.name,
          Empresa: this.$store.state.AuthModule.AuthState.company_name,
        })
        this.$emit('refresh')
            this.loading = false

      })
      .catch(() => {
            this.loading = false

      })
  }
  private validationsDone () {
    let validTasks = false
    this.tasks.map(function (element: any) {
      if (!element.completed) {
        validTasks = true
      }
    })
    // validacion arreglo de URLS
    if (this.urls_array.length === 0) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Tienen que haber URLS agregadas al terminar una actividad',
        icon_notification: 'mdi_check',
        color_notification: 'danger',
      })
      return false
    }
    // Validacion arreglo de archivos
    if (this.currentDatarooms.length === 0) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Tienen que haber Documentos de evidencia agregados al terminar una actividad',
        icon_notification: 'mdi_check',
        color_notification: 'danger',
      })
      return false
    }
    // Validacion de usuario asignado
    if (!this.userObject) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Tiene que tener un responsable asignado antes de terminar una actividad',
        icon_notification: 'mdi_check',
        color_notification: 'danger',
      })
      return false
    }
    // Validacion de Tareas Completadas
    if (validTasks) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message:
          'Para terminar un actividad deberas terminar de completar todas las tareas asociadas a la actividad',
        icon_notification: 'mdi_check',
        color_notification: 'danger',
      })
      return false
    }
    return true
  }
  private getValue (value?: any, status?: any) {
    let data: any = {
      act_id: this.selectedActivity.id,
    }
    if (status === 1) {
      data = { ...data, url: value, }
    } else if (status === 2) {
      this.$mixpanel.track('Cambio de estado', {
        Estado: value,
        Actividad: this.selectedActivity.name,
      })
      data = { ...data, status: value, }
    } else {
      //Create new url
      data = {
        ...data,
        activity_urls_attributes: [
          {
            link: value,
          },
        ],
      }
      this.$mixpanel.track('URL añadida - Impl', {
        Actividad: this.selectedActivity.name,
        Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
      })
    }
        this.loading = true

    this.$store
      .dispatch('ActivitiesModule/updateActivity', data)
      .then((response) => {
        this.urls_array = response.data.activity_urls.map((url: UrlsInterface) => ({
          link: url.link || '',
          id: url.id || '',
        }))
        this.statusFull = response.data.status
        if (
          this.valueAux === 'hackmetrix_review' &&
          !this.ADMIN_ROLES.some((adminRole: string) => this.$store.state.AuthModule.AuthState.role.includes(adminRole))
        ) {
          this.$store.dispatch('NotificactionsModule/OpenNotification', {
            message: 'Tu solicitud de revisión ha sido enviada a un experto',
            icon_notification: 'mdi_check',
          })
          this.$emit('refresh')
        }
            this.loading = false

      })
      .catch(() => {
        this.statusFull = this.statusBefore
            this.loading = false

      })
  }

  private urlsAct (value: any, id: number) {
    let dataAct: any = {
      act_id: this.selectedActivity.id,
    }

    if (!value.trim()) {
      dataAct = {
        ...dataAct,
        activity_urls_attributes: [
          {
            id: id,
            link: value,
            _destroy: true,
          },
        ],
      }
    } else {
      dataAct = {
        ...dataAct,
        activity_urls_attributes: [
          {
            id: id,
            link: value,
          },
        ],
      }
    }

    this.$store.dispatch('ActivitiesModule/updateActivity', dataAct).then(() => {
      this.$emit('refresh')
    })
  }
}
