
import { Component, Vue, } from 'vue-property-decorator'
import Avatar from 'vue-avatar'

@Component({
  components: {
    Avatar,
  },
})
export default class CommentAreaComponent extends Vue {
  public value = ''
  private username =
    this.$store.state.AuthModule.AuthState.name +
    ' ' +
    this.$store.state.AuthModule.AuthState.last_name

  changeValue () {
    this.$emit('input', this.value)
  }

  cancel () {
    if (document.activeElement != document.body) {
      const aux: any = document.activeElement
      aux.blur()
    }
    this.value = ''
  }

  sendMessage () {
    if (!this.value || /^\s*$/.test(this.value)) {
      return
    }
    this.$emit('send', this.value)
    const actividad = this.$route.path.includes('activity') ? this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name : this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName
    const evento = this.$route.path.includes('activity') ? 'Comentario - Impl' : 'Comentario - Docs'
    this.$mixpanel.track(evento, {
      Comentario: this.value, 
      Actividad: actividad,})
    if (document.activeElement != document.body) {
      const aux: any = document.activeElement
      aux.blur()
    }
    this.value = ''
  }
}
