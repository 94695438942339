
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator'
import Avatar from 'vue-avatar'
import ConfirmDialog from '@/components/ConfirmDialog/Index.vue'
import { ActivityComment, } from '@/store/types/Activities/ActivitiesGeneral'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    Avatar,
    ConfirmDialog,
    OverlayBackground
  },
  computed: {
    ...mapGetters('PolimorficModule', ['getStatusModal',]),
  },
})
export default class CommentBoxComponent extends Vue {
  @Prop({
    required: false,
    default: {
      id: 0,
      description: '',
      updated: false,
      is_owner: false,
      user_full_name: '',
      created_at: '',
      updated_at: '',
    },
  })
  comment!: ActivityComment

  @Prop({ required: true, default: false, }) act_id!: number

  public edit = false
  public showConfirmModal = false
  public textEdit = ''
  private loading = false;


  changeValue () {
    this.$emit('input', this.comment.description)
  }

  private sendMessage () {
    if (!this.textEdit || /^\s*$/.test(this.textEdit)) {
      return
    }
        this.loading = true

    this.$store
      .dispatch('ActivitiesModule/putActivitiesComment', {
        act_id: this.act_id,
        message_id: this.comment.id,
        message: this.textEdit,
      })
      .then(() => {
        this.edit = false
              this.loading = false;

        this.$emit('refresh', null)
      })
      .catch(() => {
        this.edit = false
              this.loading = false;

        this.$emit('refresh', null)
      })
  }

  private closeModal () {
    this.showConfirmModal = false
  }

  private modalAction () {
    this.showConfirmModal = true
        this.loading = true

    this.$store
      .dispatch('ActivitiesModule/deleteActivitiesComment', {
        act_id: this.act_id,
        message_id: this.comment.id,
      })
      .then(() => {
        this.showConfirmModal = false
              this.loading = false;

        this.$emit('refresh', null)
      })
      .catch(() => {
        this.showConfirmModal = false
              this.loading = false;

        this.$emit('refresh', null)
      })
  }

  @Watch('getStatusModal')
  private getStatusModalwatch () {
    this.edit = false
  }
}
