
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class InputUrl extends Vue {
  @Prop({ required: false, default: '' }) url!: string

  public edit = false
  public value = ''
  get propUrlComputed() {
    return this.url
  }

  lostFocus() {
    this.edit = false
  }

  changeValue() {
    if (this.value.trim() && /^\s*$/.test(this.value)) {
      this.edit = false
      return
    }

    this.$emit('input', this.value)
    this.edit = false
  }

  mounted() {
    if (this.propUrlComputed) {
      this.value = this.propUrlComputed
    } else {
      this.value = ''
    }
  }

  select() {
    this.edit = true
    const input: any = this.$refs.input
    setTimeout(() => {
      input.focus()
    }, 0)
  }

  open() {
    let url = ''
    if (!this.value.match(/^https?:\/\//i)) {
      url = 'http://' + this.value
      window.open(url, '_blank')
      return
    }
    window.open(this.value, '_blank')
  }

  @Watch('url')
  onUrlChange(newUrl: string) {
    this.value = newUrl
  }
}
