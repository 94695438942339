
import { Vue, Component, Prop, } from 'vue-property-decorator'
import NavigationImplementation from '@/components/V2/NavigationImplementation/Index.vue'
import SideNavImplementationComponent from '@/components/V2/SideNavImplementation/Index.vue'

@Component({
  components: {
    NavigationImplementation,
    SideNavImplementationComponent,
  },
  computed: {},
})
export default class ImplementationDetailComponent extends Vue {
  @Prop({ required: false, }) readonly articles!: any[]

  private goToarticle (value: any) {
    let activityName =  this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name
    this.$mixpanel.track(`Clic en necesitas ayuda de mantencion de ${activityName}`, { 'Articulo': value.title, 'link': value.link })
    window.open(value.link, '_blank')
  }
}
