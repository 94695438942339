
import { Component, Vue,} from 'vue-property-decorator'

@Component({})
export default class ButtonInputUrl extends Vue {

  public value = ''
  public createUrl = false

  lostFocus () {
    this.createUrl = false
    this.changeValue()
  }

  changeValue () {
    if (!this.value || /^\s*$/.test(this.value)) {
      this.createUrl = false
      return
    }

    this.$emit('input', this.value)
    this.createUrl = false
    this.value =''
  }

  mounted () {
    this.createUrl= false
    this.value = ''
  }

  select () {
    this.createUrl= true
    const input: any = this.$refs.input
    setTimeout(() => {
      input.focus()
    }, 0)
    
  }
}
