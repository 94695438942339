var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"document-item",attrs:{"w":12}},[_c('div',{staticClass:"label"},[_vm._v("Responsable")]),_c('div',{staticClass:"item flex cursor-pointer button-upload-file",class:{ noEvents: _vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole)) },on:{"click":function($event){_vm.showDialog = !_vm.showDialog}}},[_c('label',{staticClass:"flex cursor-pointer"},[(_vm.userSelected.full_name)?_c('avatar',{attrs:{"username":_vm.userSelected.full_name,"size":32,"customStyle":{
          'font-weight': 'bold',
          'font-size': '14px',
          'margin-left': '10px',
          'margin-right': '10px',
        },"color":"#fff"}}):_c('img',{attrs:{"src":require("@/assets/icons/user-icon.svg"),"width":"20"}}),(_vm.userSelected.full_name)?_c('div',{staticClass:"nam ml-1"},[_vm._v(_vm._s(_vm.userSelected.full_name))]):_c('div',{staticClass:"nam ml-1"},[_vm._v(_vm._s(_vm.getNameComponent))])],1)]),(_vm.showDialog)?_c('div',{staticClass:"list-users"},_vm._l((_vm.usersProfile.data),function(dp,index){return _c('div',{key:index,staticClass:"user-item",on:{"click":function($event){return _vm.selectUser(dp)}}},[_c('div',[_c('avatar',{attrs:{"username":dp.full_name,"size":32,"customStyle":{
            'font-weight': 'bold',
            'font-size': '14px',
            'margin-left': '10px',
            'margin-right': '10px',
          },"color":"#fff"}})],1),_c('div',[_vm._v(_vm._s(dp.full_name))])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }