
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator'
import SelectComponent from '@/components/SelectHackmetrix/Index.vue'
import { UserInterface, } from '@/store/types/User/Index'

import { mapGetters, } from 'vuex'
import Avatar from 'vue-avatar'

@Component({
  components: {
    SelectComponent,
    Avatar,
  },
  computed: {
    ...mapGetters('PolimorficModule', { usersProfile: 'getUsersCompany', }),
  },
})
export default class DialogTemplatesComponent extends Vue {
  @Prop({ required: true, default: {}, }) readonly userObject!: UserInterface
  private business = this.$store.state.BusinessModule.BusinessState.businessSelected.company
  private showDialog = false
  public usersProfile: any
  private userSelected = {}
  private role = this.$store.state.AuthModule.AuthState.role
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];

  @Watch('userObject')
  changeUser () {
    this.userSelected = this.userObject
  }

  mounted () {
    if (this.userObject) {
      this.userSelected = this.userObject
    }
    if (!this.ADMIN_ROLES.some((adminRole: string) => this.role.includes(adminRole))){
      this.getUsersByCompany()
    }
  }

  private selectUser (value: any) {
    this.userSelected = value
    this.showDialog = !this.showDialog
    this.$emit('updateUser', this.userSelected)
  }

  private getUsersByCompany () {
    this.$store.dispatch('PolimorficModule/GetUsersByCompany', this.business.id)
  }

  get getNameComponent () {
    if (!this.ADMIN_ROLES.some((adminRole: string) => this.role.includes(adminRole))){
      return 'Asignar responsable'
    } else {
      return 'Sin responsable asignado'
    }
  }
}
