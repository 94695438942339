
import { Vue, Component, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'
import { ActivityInterface, } from '../../../store/types/Activities/ActivitiesGeneral'

@Component({
  components: {},
  computed: {
    ...mapGetters('ActivitiesModule', { selectedActivity: 'getSelectedActivity', }),
    ...mapGetters("AuthModule", ["getCompanyIdSelected"]),

  },
})
export default class ImplementationDetailComponent extends Vue {
  public selectedActivity: any
  public getCompanyIdSelected!: string;

  private goBack () {
    this.$mixpanel.track('Volver al listado')

    const data = this.$store.state.ActivitiesModule.ActivitiesState.CompanyActivities
    const index = data.findIndex(
      (element: ActivityInterface) => element.id == this.selectedActivity.id
    )

    if (this.$route.path.includes('startup'))
      this.$router.replace({ path: `/startup/activity?companyId=${this.getCompanyIdSelected}#${data[index].phase}`, })
    else {
      this.$router.replace({
        path: `/app/clients/${this.$route.params.id}/plan#${data[index].phase}`,
      })
    }
  }

  private next () {
    const data = this.$store.state.ActivitiesModule.ActivitiesState.CompanyActivities
    let id: any = this.$route.params.task_id

    const index = data.findIndex((act: ActivityInterface) => act.id == id)

    id = !data || data.length > index + 1 ? data[index + 1].id : data[0].id
    const name = this.$route.path.includes('startup') ? 'activityDetailClient' : 'activityDetail'
    this.$mixpanel.track('Siguiente')
    this.$router.push({ name, params: { task_id: id, }, })
    this.$emit('getActivityDetail')
    this.$emit('getActivityComments')
    this.$emit('getSteppers')
  }

  private back () {
    const data = this.$store.state.ActivitiesModule.ActivitiesState.CompanyActivities

    let id: any = this.$route.params.task_id
    const index = data.findIndex((act: ActivityInterface) => act.id == id)

    id = !data || 0 > index - 1 ? data[data.length - 1].id : data[index - 1].id
    const name = this.$route.path.includes('startup') ? 'activityDetailClient' : 'activityDetail'
    this.$mixpanel.track('Anterior')
    this.$router.push({ name, params: { task_id: id, }, })
    this.$emit('getActivityDetail')
    this.$emit('getActivityComments')
    this.$emit('getSteppers')
  }
}
