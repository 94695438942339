var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"zone"},[(_vm.loading)?_c('OverlayBackground'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('avatar',{attrs:{"username":_vm.comment.user_full_name,"size":32,"customStyle":{
        'font-weight': 'bold',
        'font-size': '14px',
        'margin-left': '10px',
        'margin-right': '10px',
      },"color":"#fff"}}),_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(_vm.comment.user_full_name)+" "),_c('span',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.comment.updated_at))+" ")]),(_vm.comment.updated)?_c('span',{staticClass:"edit"},[_vm._v(" (editado) ")]):_vm._e()])],1),(!_vm.edit)?_c('div',[_c('div',{staticClass:"message"},[_vm._v(" "+_vm._s(_vm.comment.description)+" ")]),(_vm.comment.is_owner)?_c('div',{staticClass:"options"},[_c('div',{on:{"click":function($event){_vm.edit = true
          _vm.textEdit = _vm.comment.description}}},[_vm._v(" Editar ")]),_c('div',{staticClass:"ml-1",on:{"click":function($event){_vm.showConfirmModal = true}}},[_vm._v("Eliminar")])]):_vm._e()]):_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.textEdit),expression:"textEdit"}],attrs:{"placeholder":"Añade un comentario"},domProps:{"value":(_vm.textEdit)},on:{"input":function($event){if($event.target.composing)return;_vm.textEdit=$event.target.value}}}),(_vm.comment.is_owner)?_c('div',{staticClass:"options"},[_c('vs-button',{attrs:{"active":"","relief":""},on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v("Guardar")]),_c('vs-button',{attrs:{"transparent":""},on:{"click":function($event){_vm.edit = false}}},[_vm._v(" Cancelar")])],1):_vm._e()]),_c('ConfirmDialog',{attrs:{"title":'¿Estás seguro?',"subtitle":'Estas por eliminar un comentario de la actividad',"action":'Eliminar',"danger":"","showDialog":_vm.showConfirmModal},on:{"close":_vm.closeModal,"action":_vm.modalAction}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }